import React from "react";

const DeleteForm = () => {
    return (
        <>
            Are you sure you want to delete this Key? <br />All associated translations will be deleted too.
        </>
    )
};

export default DeleteForm;